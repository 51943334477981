import isEmpty from "lodash/isEmpty";
import map from "lodash/map";

import { Carousel, Row, Col } from "antd";
import Link from "next/link";
import ProductCard from "components/product/card/ProductCard";

import styles from "./Carousels.module.scss";
import {ArrowRightOutlined} from "@ant-design/icons";

const CAROUSEL_COUNT = 3;

const carouselSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  slidesToShow: CAROUSEL_COUNT,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
      },
    },
  ],
};

const Carousels = ({ carousels }) => {
  if (isEmpty(carousels)) {
    return null;
  }

  return (
    <>
      {map(carousels, (carousel, index) => {
        const { id, title, entities, description, hash, url } = carousel;
        if (isEmpty(entities)) {
          return "";
        }
        let customCarouselSettings = { ...carouselSettings };
        if (entities.length < CAROUSEL_COUNT + 1) {
          customCarouselSettings.infinite = false;
        }

        return (
          <div key={`custom-carousels-${id}-${index}`}>
            <Row>
              <Col xs={24}>
                <h2 className="title">{title}</h2>
              </Col>
            </Row>
            <Row className={styles?.carouselRow}>
                  {map(entities, (product, index) => {
                    return (
                        <Col
                            xs={12}
                            md={6}
                            className={styles.productsWrapper}
                            key={`products-${product.id}-${index}`}
                        >
                          <div
                            className={styles?.productCard}
                          >
                            <ProductCard {...product} />
                          </div>
                        </Col>
                    );
                  })}
              <Col xs={12} md={6}>
                {!isEmpty(description) ? (
                  <div className={styles.description}>
                    <Link href={url}>
                      <a className={styles[`item-${hash}`]}>
                      <div>
                        {carousel?.description}
                        <ArrowRightOutlined/>
                        </div>
                      </a>
                    </Link>
                  </div>
                ) : null}
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

export default Carousels;
