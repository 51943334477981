import { useSWRInfinite } from "swr";
import map from "lodash/map";
import toNumber from "lodash/toNumber";
import LoadingIcon from "components/_other/icons/LoadingIcon";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useRouter } from "next/router";
import styles from "./Infinite.module.scss";
import Link from "next/link";

const Infinite = ({
	                  totalCount = 0,
	                  pageCount = 1,
	                  currentPage = 0,
	                  perPage = 0,
	                  handleGetKey,
	                  handleGetHref,
	                  render,
	                  className = "",
	                  destinationElementId = null,
                  }) => {
	const {
		data,
		size: actualPage,
		setSize: setActualPage,
		isValidating,
	} = useSWRInfinite(handleGetKey, (url) => fetch(url).then((r) => r.json()), {
		initialSize: currentPage > 1 ? currentPage - 1 : 0,
	});

	const router = useRouter();
	const [isBrowser, setIsBrowser] = useState(false);
	const [inUse, setInUse] = useState(true);

	useEffect(() => {
		setIsBrowser(true);
	}, []);

	if (isBrowser) {
		return (
			<>
				{inUse
					? createPortal(
						map(data, (page, index) => {
							if (index < currentPage) {
								return;
							}

							return render(page);
						}),
						document.getElementById(destinationElementId)
					)
					: ""}
				{actualPage < pageCount ? (
					<div className={className}>
						<Link
							href={"/"}
							scroll={false}
						>
							<a
								className="btn btn-yellow"
								onClick={(e) => {
									e.preventDefault;
									setInUse(true);
									const newPage = actualPage ? actualPage + 1 : 2;
									setActualPage(newPage);
									handleGetHref(newPage);
								}}
							>
								{isValidating ? (
									<span className={styles.loading}>
										<LoadingIcon />
									</span>
								) : (
									"Zobraziť ďalšie miesta"
								)}
							</a>
						</Link>
					</div>
				) : (
					""
				)}
			</>
		);
	}

	return null;
};

export default Infinite;
